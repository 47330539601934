/**
 * Light weight custom event manager
 *
 * Author: Patrick Heiss
 */

"use strict";

/**
 * Constructor
 */
var EventManager = function () {
	this._listeners = {};
};

/**
 * Add a new "listenerName" listener with "callback" function
 *
 * @param {string} listenerName
 * @param {function} callback
 * @param {object} instance [optional]
 */
EventManager.prototype.on = function (listenerName, callback, instance) {
	(this._listeners[listenerName] = this._listeners[listenerName] || []).push({
		"instance": instance,
		"callback": callback
	});
};

/**
 * Remove all listeners for a given "listenerName" or remove one specific listener in
 * the same "listenerName" + "instance" context
 *
 * @param {string} listenerName
 * @param {object} [instance]
 */
EventManager.prototype.off = function (listenerName, instance) {
	if (this._listeners.hasOwnProperty(listenerName)) {
		if (typeof instance === "undefined" || this._listeners[listenerName].length === 0) {
			delete this._listeners[listenerName];
		}
		else {
			var listeners = this._listeners[listenerName];
			for (var i = 0, len = listeners.length; i < len; i++) {
				if (listeners[i].instance === instance) {
					listeners.splice(i, 1);
					return;
				}
			}
		}
	}
};

/**
 * Fire the callbacks of all "listenerName" listeners
 *
 * @param {string} listenerName string
 * @param {object|string} [data]
 * @param {object} [instance] mixed skip firing listeners in the same context
 */
EventManager.prototype.fire = function (listenerName, data, instance) {
	if (this._listeners.hasOwnProperty(listenerName)) {
		var listeners = this._listeners[listenerName];
		for (var i = 0, len = listeners.length; i < len; i++) {
			if (typeof instance === "undefined" || instance !== listeners[i].instance) {
				listeners[i].callback.apply(window, data);
			}
		}
	}
};